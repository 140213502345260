<template>
  <v-row class="px-3 pt-3">
    <v-col lg="12">
      <v-row>
        <v-btn class="mr-2 mb-2" v-if="!edit" color="primary lighten-3" @click="modalActualizarCelular" small>
          <v-icon class="pr-2" small>mdi-phone</v-icon>actualizar Celular
        </v-btn>
        <v-btn v-if="!edit" class="mr-2" color="primary lighten-3" @click="modalActualizarCorreo" small>
          <v-icon class="pr-2" small>mdi-email</v-icon>actualizar Correo
        </v-btn>
        <v-btn v-if="!edit" color="white" @click="modalActualizarWhatsapp" small>
          <img src="/whatsapp-logo.png" width="20px" class="mr-2"> actualizar Whatsapp
        </v-btn>
      </v-row>
    </v-col>
    <v-form ref="form" v-model="valid" lazy-validation>
      <Form :modelo="modelo" :fields="fields_persona" :edit="edit" :create="false" url />
    </v-form>
  
    <ActualizarContacto 
      v-if="dialog" 
      :dialog="dialog" 
      :url="url" 
      :label="label" 
      :value="valueEdit"
      :rules="rulesEdit"
      @modificado="modificarContacto"
      @cancelar="dialog=false"
    />
  </v-row>
</template>
<script>
import Form from "@/common/util/crud/Form";
import ActualizarContacto from "@/components/ActualizarContacto";
export default {
  components: { Form,ActualizarContacto },
  data() {
    return {
      valid: true,
      titulo: "Información Personal",
      fields_persona: [],
      edit: false,
      modelo: Object.assign({}, this.$store.state.abogado.persona),
      dialog:false,
      label:'',
      valueEdit:null,
      url:'',
      rulesEdit:'required'
    };
  },
  mounted() {
    this.fields_persona = [
      {
        label: "Nombre(s)",
        model: "nombres",
        type: "text",
        class: "lg2 xs6",
        rules: "required",
        classField: "required",
        disabled: true
      },
      {
        label: "Primer Apellido",
        model: "primerApellido",
        type: "text",
        class: "lg2 xs6",
        rules: "required",
        classField: "required",
        disabled: true
      },
      {
        label: "Segundo Apellido",
        model: "segundoApellido",
        type: "text",
        class: "lg2 xs6",
        disabled: true
      },
      {
        label: "Género",
        model: "genero",
        type: "select",
        rules: "requiredSelect",
        classField: "required",
        items: [
          {
            text: "MASCULINO",
            value: "MASCULINO"
          },
          {
            text: "FEMENINO",
            value: "FEMENINO"
          }
        ],
        class: "lg2 xs6",
        disabled: true
      },
      {
        label: "Numero Documento",
        model: "numeroDocumento",
        type: "text",
        class: "lg2 xs6",
        rules: "required",
        classField: "required",
        disabled: true
      },
      {
        label: "Complemento",
        model: "complementoDocumento",
        type: "text",
        class: "lg2 xs6",
        disabled: true
      },
      // {
      //   label: "Expedicion",
      //   model: "lugarEmisionDocumento",
      //   type: "text",
      //   class: "lg2 xs6",
      //   rules: "requiredSelect",
      //   classField: "required",
      //   disabled: true
      // },
      {
        label: "Fecha Nacimiento",
        model: "fechaNacimiento",
        type: "date",
        birthday: true,
        class: "lg2 xs6",
        disabled: true
      },
      {
        label: "Número Celular",
        model: "celular",
        type: "text",
        class: "lg2 xs6",
        rules: "required",
        classField: "required"
      },
      {
        label: "Correo Electrónico",
        model: "correo",
        type: "text",
        class: "lg3 xs6",
        rules: "required email",
        classField: "required"
      },
      {
        label: "Whatsapp",
        model: "whatsapp",
        type: "text",
        class: "lg3 xs6",
        rules: "required email",
        classField: "required",
        prepend:{
          img:'/whatsapp-logo.png'
        }
      }
    ];
  },
  methods: {    
    modalActualizarCorreo(){
      this.label = 'Correo'
      this.dialog = true 
      this.valueEdit=this.$store.state.abogado.persona.correo
      this.url = 'abogado-ciudadano/correo'
      this.rulesEdit='required email'
    },
    modalActualizarCelular(){
      this.label = 'Celular'
      this.valueEdit=this.$store.state.abogado.persona.celular
      this.dialog = true 
      this.url = 'abogado-ciudadano/celular'
      this.rulesEdit='required'
    },
    modalActualizarWhatsapp(){
      this.label = 'Whatsapp'
      this.valueEdit=this.$store.state.abogado.persona.whatsapp
      this.dialog = true 
      this.url = 'abogado-ciudadano/whatsapp'
      this.rulesEdit='required celular'
    },
    modificarContacto(data){
      if(this.label=='Celular'){
        this.modelo.celular=data.celular
      }
      if(this.label=='Correo'){
        this.modelo.correo=data.correo
      }
      if(this.label=='Whatsapp'){
        this.modelo.whatsapp=data.whatsapp
      }
      this.dialog=false
    }
  }
};
</script>
